.bgColor {
    background-color: #F2F8FD;
    position: relative;
}

.searchDiv {
    position: absolute;
    top: 21%;
    left: 24%;
    width: 43.5%;
}

.centerH4 {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 32px;

}

.borderBottom {
    border-bottom: 1px solid var(--color-gray-40);
}

.minMaxWidth550 {
    min-width: 550px;
    max-width: 550px;
}

.minMaxWidth880 {
    min-width: 880px;
    max-width: 880px;
}

.cursorPointer {
    cursor: pointer;
}

.no-bullet-padding ul {
    list-style-type: none;
}

.no-bullet-padding li {
    padding: 0;
    margin: 0;
    margin-bottom: 1em;
}

.serachClass {
    width: auto;
    height: 187px;
    flex-shrink: 0;
    background: var(--background-neutral, #FFF);
    box-shadow: 0px 9px 14px 0px rgba(0, 0, 0, 0.06);
    padding: 0 var(--muse-space-160);
}

.resourceClass {
    width: 100%;
    display: block;
    background: #FFF;
}

.textAlignCenter {
    text-align: center;
}

.resoureceDivLabel {
    color: var(--text-default, #1B1C1D);
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 32px;
    font-style: normal;
    font-weight: 300 !important;
    line-height: 40px;
}

.cardDiv {
    display: flex;
    width: 100%;
    max-width: 23%;
    height: max-content;
    flex-direction: column;
    align-items: flex-start;
    flex-shrink: 0;
    border-radius: 16px;
    background: #FFF;
    padding: 0;
    /* box-shadow: 0px 12px 48px 0px rgba(27, 28, 29, 0.10); */
    /* background: var(--background-information-weak, #F2F8FD); */
    margin-bottom: var(--muse-space-130);
}

.cards {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    width: 100%;
    column-gap: 24px;
    padding: 0 var(--muse-space-140);
    justify-content: center;
}

.resourceIcons {
    position: relative;
    top: -40px;
    align-self: center;
    /* left: 38%; */
}

.resourceIconEditView {
    position: relative;
    align-self: center;
}

.resourceLabeEditView {
    position: relative;
    width: 100%;
    /* height: 56px; */
    display: flex;
    align-items: center;

    .icons {
        height: 16px;
        width: 16px;
        margin: 0 4px;
        cursor: pointer;
        color: #606162;
    }
}

.resourceLabel {
    /* position: absolute;
    width: 20.2%; */
    position: relative;
    align-self: center;
    text-align: center;
    width: 70%;
    height: 38px;
    top: -30px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.resourceLabelOthers {
    position: relative;
    align-self: center;
    text-align: center;
    width: 55%;
    height: 38px;
    top: -33px;
    display: flex;
    left: 5%;
    align-items: center;
    justify-content: center;
}

.resourceLabel h5 {
    top: 40px;
    position: relative;
    align-items: center;
    text-align: center;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    color: var(--text-default, #1B1C1D);
    font-feature-settings: 'clig' off, 'liga' off;
    font-style: normal;
}

.resourceLinks {
    position: 'relative';
    background: #FFF;
    height: 445px;
    width: 100%;
    /* overflow-x: hidden;
    overflow-y: auto; */
    border-bottom-right-radius: 16px;
    border-bottom-left-radius: 16px;
}

.resourceLinksEditView {
    background: #FFF;
    height: 190px;
    overflow: auto;

    .icons {
        height: 16px;
        width: 16px;
        margin: 0 4px;
        cursor: pointer;
        color: #606162;
    }
}

.height-200 {
    height: 200px;
}

.boxShadowNone {
    box-shadow: none;
    padding: 15px;
    color: var(--text-accent, #0070D2);
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 24px;
    cursor: pointer;
}

.boxShadowNone a {
    color: var(--muse-text-accent) !important;
    text-decoration: none !important;
}

.boxShadowNone a:focus-visible {
    box-shadow: 0 0 0 2px var(--muse-border-inverse), 0 0 0 4px var(--muse-border-inverse), 0 0 0 6px var(--muse-border-accent) !important;
    outline: none;
    border-radius: 4px;
}

.faqMainDiv {
    width: auto;
    display: block;
    flex-shrink: 0;
    margin-bottom: 64px;
    /* background: var(--background-information-weak, #F2F8FD); commented as per brnad compliance feedback */
}

.faqSectionDivs {
    display: flex;
    /* width: 100%; */
    flex-wrap: wrap;
    flex-direction: row;
    column-gap: 50px;
    justify-content: space-between;
    padding: 0 var(--muse-space-160);
    margin: 0 5px;
}

.mainFaqEditSection {
    width: 100%;
    padding: 0 var(--muse-space-160);
}

.faqSectionDivsChild {
    width: 100%;
    max-width: 51.5%;
}

.faqSectionDivsChild2 {
    width: 100%;
    max-width: 40%;
}

.mT56 {
    margin-top: 56px;
}

.faqLabel {
    color: var(--text-default, #1B1C1D);
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 32px;
    font-style: normal;
    font-weight: 300;
    line-height: 40px;
}

.faqSubLabel {
    color: var(--text-default, #1B1C1D);
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 16px;
    font-style: normal;
    font-weight: bold;
    line-height: 24px;
}

.accordianDiv {
    height: 282px;
}

.mainPageAccordionDivMaxHeight {
    max-height: 300px;
    overflow: auto;
    padding: 0 !important;
}

.nonLoginSubmitEnquiry {
    width: 100%;
    height: 210px;
    flex-shrink: 0;
    border-radius: var(--border-radius-30, 8px);
    background: #FFF;
    /* box-shadow: 0px 2px 16px 0px rgba(27, 28, 29, 0.08); */
    display: flex;
    flex-direction: row;
}

.LoginSubmitEnquiry {
    width: 100%;
    height: 419px;
    flex-shrink: 0;
    border-radius: var(--border-radius-30, 8px);
    background: #FFF;
    box-shadow: 0px 2px 16px 0px rgba(27, 28, 29, 0.08);
    display: flex;
    flex-direction: row;
    overflow: hidden;
}

.signInDiv {
    width: 100%;
    height: 210px;
    flex-shrink: 0;
    border-radius: var(--border-radius-30, 8px);
    background: #FFF;
    /* box-shadow: 0px 2px 16px 0px rgba(27, 28, 29, 0.08); */
    margin-top: 29px;
}

.foundLabel p {
    font-size: 24px;
    font-style: normal;
    font-weight: 300;
    line-height: 32px;
    margin-left: 20px;
}

.foundLabel button {
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 10px;
    margin-top: 32px;
    cursor: pointer;
}

.foundLabelLoggedIn p {
    font-size: 24px;
    font-style: normal;
    font-weight: 300;
    line-height: 32px;
    margin-left: 20px;
    margin-top: 35px;
    word-break: break-all;
}

.foundLabelLoggedIn button {
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 10px;
    margin-top: 32px;
    cursor: pointer;
    margin-left: 20px;
}

.foundLabeMobile p {
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: 32px;
    margin-left: 20px;
}

.foundLabeMobile button {
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 10px;
    margin-top: 32px;
    cursor: pointer;
}

.getHelpLabel {
    font-size: 24px;
    font-style: normal;
    font-weight: 300;
    line-height: 32px;
    margin-top: 13px;
    margin-left: 16px;
    display: inline-block;
}

.signInInnerDiv button {
    margin-left: 16px;
    line-height: 10px;
    width: 129px;
    height: 42px;
}

.mt20 {
    margin-top: 20px;
}

.mt13 {
    margin-top: 13px;
}

.mt6 {
    margin-top: 6px;
}

.mt26 {
    margin-top: 26px;
}

.mt50 {
    margin-top: 50px;
}

.mt69 {
    margin-top: 69px;
}

.displayFelx {
    display: flex;
}

.displayFelx div:nth-of-type(1) {
    display: grid;
    width: -webkit-fill-available;
}

.justifyCenter {
    justify-content: center;
}

.welcomelabelInput {
    position: relative;
    z-index: 2000;
    left: 22%;
    width: 682px;
    top: -76%;
    max-width: 56%;
}

.welcomelabelInput h1 {
    text-align: center;
    font-size: 32px;
    font-style: normal;
    font-weight: 300;
    line-height: 40px;
}

/* .welcomelabelInput .input-wrapper{
    background: var(--color-background-gray);
} */
.welcomelabelInputLoggedIn {
    position: relative;
    z-index: 2000;
    left: 26%;
    width: 682px;
    top: -76%;
    max-width: 56%;
}

.welcomelabelInputLoggedIn h1 {
    text-align: center;
    font-size: 32px;
    font-style: normal;
    font-weight: 300;
    line-height: 40px;
}

/* .welcomelabelInputLoggedIn .input-wrapper{
    background: var(--color-background-gray);
} */
.marLR18 {
    margin-left: 18px;
    margin-top: 18px;
}

.marL18 {
    margin-left: 18px;
}

.marB20 {
    margin-bottom: 20px;
}

.displayConetent {
    display: contents;
    font-size: 16px;
}

.fontWeight700 {
    font-weight: 700;
}

.enquirySubmit {
    border-radius: 48px;
    display: inline-flex;
    height: 42px;
    padding: var(--space-50, 16px) var(--space-70, 24px);
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    margin-left: 20px;
}

.nonLoginSubmitEnquiryMobile .enquirySubmit {
    border-radius: 48px;
    display: inline-flex;
    height: 38px;
    padding: var(--space-50, 16px) var(--space-70, 24px);
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    margin-left: 0;
    font-size: 14px;
}

.faqButton {
    display: flex;
    width: 100%;
    /* height: 32px; */
    padding: var(--space-50, 16px) var(--space-70, 24px);
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-weight: bold;
}

.mobileView .muse-accordion:first-child .muse-divider {
    display: none;
}

.mobileView {
    margin: 0 var(--muse-space-50);
    box-shadow: 0px 12px 48px 0px rgba(27, 28, 29, 0.10);
    margin-bottom: 16px;
}

.innerMobileDiv {
    padding: 3px 20px 10px 7px;
}

.mobileViewResourceTitle {
    margin-left: 14px;
    font-size: 14px;
    font-weight: bold;
}

.mobileViewinnerText {
    padding-left: var(--muse-space-100);
}

.mobileViewinnerTextUl a {
    text-decoration: none !important;
    color: var(--muse-text-accent) !important;
}

.mobileResourceInnerSpan {
    color: #8E8E8E;
}

.nonLoginSubmitEnquiryMobile {
    width: 100%;
    height: 140px;
    flex-shrink: 0;
    border-radius: var(--border-radius-30, 8px);
    background: #FFF;
    /* box-shadow: 0px 2px 16px 0px rgba(27, 28, 29, 0.08); */
    display: flex;
    flex-direction: row;
}

.padding160 {
    padding: 0 var(--muse-space-160);
}

.modelStyle {
    left: 25%;
    position: relative;
    width: 50%;
}

#searchLady {
    top: 10px;
    position: relative;
}

/* Less than 600 */
@media only screen and (max-width:600px) {
    .cardDiv {
        display: none;
    }

    .serachClass {
        padding: 0 var(--muse-space-50);
    }

    .welcomelabelInput {
        max-width: 100% !important;
        width: 100% !important;
        left: 0 !important;
    }

    .welcomelabelInput h1 {
        font-size: 24px;
    }

    .mobileView {
        display: block;
    }

    .nonLoginSubmitEnquiry {
        display: none;
    }

    .nonLoginSubmitEnquiryMobile {
        display: flex;
    }

    .muse-mt-140 {
        margin-top: 11px;
    }

    .faqSectionDivs {
        padding: 0 var(--muse-space-50);
    }

    /* .tabViewFaqBtn {
        top: -220px !important;
    } */

    .tabViewFaqBtn a {
        font-size: 14px;
    }

    .tabViewFaqBtnLoggedIn {
        position: relative;
    }

    .tabViewFaqBtnLoggedIn a {
        font-size: 14px;
    }

    .signInDiv {
        display: none;
    }

    .faqLabel {
        font-size: 24px;
        font-weight: bold;
    }

    .faqSubLabel {
        font-size: 16px;
        font-weight: bold;
    }

    .muse-text-style-title-reduced,
    .muse-accordion-body {
        font-size: 16px !important;
    }

    .resoureceDivLabel {
        font-size: 24px;
    }

    .__react_component_tooltip {
        display: none !important;
    }

    .mobileViewEnquiry {
        padding: 0 var(--muse-space-50);
    }

    .mobileViewEnquiry h2 {
        font-size: 24px;
    }

    .mobileViewEnquiry p {
        font-size: 16px;
    }

    .mobileViewEnquiry .muse-mt-150 {
        margin-top: 50px;
    }

    .mobileViewEnquiry .minMaxWidth550 {
        min-width: auto;
    }

    .modelStyle {
        left: 12.5%;
    }

    .cards {
        padding: 0 var(--muse-space-160);
    }

    .breadcrumbs {
        padding: 0 var(--muse-space-50) !important;
    }

    .welcomelabelInputLoggedIn {
        top: 0%;
        left: 0;
        max-width: 100%;
    }

    .welcomelabelInputLoggedIn h1 {
        font-size: 24px;
        text-wrap: pretty;
    }

}

/* Greater than 600 */
@media only screen and (min-width: 600px) {
    .cardDiv {
        width: 100%;
        max-width: 100%;
        display: flex;
    }

    .mobileView {
        display: none;
    }

    .nonLoginSubmitEnquiry {
        display: flex;
    }

    .nonLoginSubmitEnquiryMobile {
        display: none;
    }

    .signInDiv {
        display: block;
    }

    .__react_component_tooltip {
        display: block !important;
    }

    .cards {
        padding: 0 var(--muse-space-160);
    }

    .tabViewFaqBtnLoggedIn {
        position: relative;
    }

    .tabViewFaqBtnLoggedIn a {
        font-size: 14px;
    }

    .welcomelabelInputLoggedIn {
        top: 13%;
        left: 0%;
        max-width: 100%;
    }
}

@media only screen and (min-width: 768px) {
    .cardDiv {
        width: 100%;
        max-width: 37.5%;
    }

    .resourceLabelOthers {
        width: 60%;
    }

    .cards {
        padding: 0 var(--muse-space-150);
    }
}

@media only screen and (min-width: 992px) {
    .cardDiv {
        width: 100%;
        max-width: 35%;
    }

    /* .resourceIcons{
        left: 30%;
    } */
    .faqSectionDivsChild {
        max-width: 100%;
    }

    .tabViewFaqBtnLoggedIn {
        position: relative;
    }

    .tabViewFaqBtnLoggedIn a {
        font-size: 14px;
    }

    .welcomelabelInputLoggedIn {
        top: -102%;
        left: 21%;
        max-width: 56%;
    }

    .welcomelabelInputLoggedIn h1 {
        /* word-break: break-all;
        word-wrap: break-word; */
        width: 90%;
        left: 7%;
        position: relative;
        text-wrap: pretty;
    }
}

@media only screen and (max-width: 992px) {
    #searchLady {
        display: none;
    }

    .welcomelabelInput {
        position: relative;
        left: 15%;
        width: 682px;
        top: 15%;
        max-width: 70%;
    }

    .faqSectionDivsChild {
        max-width: 100%;
    }

    .faqSectionDivsChild2 {
        max-width: 100%;
        margin-top: 90px;
    }

    .tabViewFaqBtn {
        position: relative;
    }

    .cards {
        padding: 0 var(--muse-space-160);
    }

}

@media only screen and (min-width: 1024px) {
    .cardDiv {
        width: 100%;
        max-width: 23%;
    }

    .cardWidth {
        width: 100%;
        max-width: 46%;
        padding: var(--muse-space-60);
        box-shadow: var(--muse-elevation-default);
    }

    /* .resourceIcons{
        left: 38%;
    } */
    .faqSectionDivsChild {
        max-width: 100%;
    }

    .faqSectionDivsChild2 {
        max-width: 100%;
        margin-bottom: 20px;
    }

    .welcomelabelInput {
        top: -99%;
    }

    .welcomelabelInput h1 {
        text-wrap: pretty;
        width: 70%;
        position: relative;
        left: 16%;
        bottom: -7px;
    }

    .welcomelabelInputLoggedIn {
        top: -99%;
        left: 22%;
    }

    .welcomelabelInputLoggedIn h1 {
        /* word-break: break-all;
        word-wrap: break-word; */
        width: 70%;
        left: 15%;
        position: relative;
        text-wrap: pretty;
    }
}

@media only screen and (min-width: 1280px) {
    .faqSectionDivsChild {
        max-width: 54.5%;
    }

    .faqSectionDivsChild2 {
        max-width: 40%;
    }

    .welcomelabelInput {
        top: -78%;
    }

    .welcomelabelInput h1 {

        width: 75%;
        left: 15%;
        position: relative;
    }

    .welcomelabelInputLoggedIn {
        top: -96%;
    }

    .welcomelabelInputLoggedIn h1 {
        /* word-break: break-all;
        word-wrap: break-word; */
        width: 80%;
        left: 12%;
        position: relative;
        text-wrap: wrap;
    }

    .tabViewFaqBtnLoggedIn {
        top: 0;
    }

    .tabViewFaqBtnLoggedIn a {
        font-size: 14px;
    }
}

@media only screen and (min-width: 1366px) {
    .faqSectionDivsChild {
        max-width: 54.5%;
    }

    .faqSectionDivsChild2 {
        max-width: 40%;
    }

    .welcomelabelInput {
        top: -76%;
    }

    .welcomelabelInput h1 {
        width: 76%;
        left: 15%;
        position: relative;
    }

}

@media only screen and (min-width: 1446px) {
    .welcomelabelInput {
        left: 24%;
    }

    .faqSectionDivsChild {
        max-width: 54.5%;
    }

    .faqSectionDivsChild2 {
        max-width: 40%;
    }

    .welcomelabelInputLoggedIn {
        left: 23%;
    }
}

@media only screen and (min-width: 1500px) {
    .welcomelabelInput {
        left: 25%;
    }

    .faqSectionDivsChild {
        max-width: 54.5%;
    }

    .welcomelabelInputLoggedIn {
        left: 25%;
    }
}

@media only screen and (min-width: 1700px) {
    .welcomelabelInput {
        left: 26.5%;
    }

    .faqSectionDivsChild {
        max-width: 54.5%;
    }

    .welcomelabelInputLoggedIn {
        left: 26%;
    }
}

.customTooltip {
    width: 100%;
    max-width: 20%;
    border: .5px solid #D6D6D6 !important;
    border-radius: 5px !important;
}

.customTooltip::after {
    border: .5px solid #D6D6D6 !important;
}



.openModal {
    display: block !important;
}

/* The Modal (background) */
.modal {
    display: none;
    /* Hidden by default */
    position: fixed;
    /* Stay in place */
    z-index: 1;
    /* Sit on top */
    padding-top: 100px;
    /* Location of the box */
    left: 0;
    top: 0;
    width: 100%;
    /* Full width */
    height: 100%;
    /* Full height */
    overflow: auto;
    /* Enable scroll if needed */
    background-color: rgb(0, 0, 0);
    /* Fallback color */
    background-color: rgba(0, 0, 0, 0.4);
    /* Black w/ opacity */
}

.muse-modal {
    --muse-modal-scrim: rgba(27 29 28 / 50%);

    align-items: center;
    background-color: var(--muse-modal-scrim);
    block-size: 100vh;
    display: flex;
    inline-size: 100vw;
    justify-content: center;
    position: fixed;
    z-index: var(--muse-z-index-top);
}

.muse-modal-dialog {
    position: relative;
    background-color: var(--muse-background-neutral);
    box-shadow: var(--muse-elevation-high);
    max-height: 100%;
    max-width: 100%;
    min-height: 100%;
    min-width: 100%;
    padding: var(--muse-space-90) var(--muse-space-50);

    @media (width >=600px) {
        padding: var(--muse-space-140);
        max-height: 50vh;
        max-width: 677px;
        min-width: auto;
        min-height: fit-content;
    }

    @media (width >=1024px) {
        max-width: 736px;
    }

    @media (width >=1440px) {
        max-width: 792px;
    }
}

.muse-modal-dialog-close {
    color: var(--muse-text-default);
    position: absolute;
    right: var(--muse-space-50);
    top: var(--muse-space-50);

    @media (width >=600px) {
        right: var(--muse-space-70);
        top: var(--muse-space-70);
    }
}

.muse-modal-dialog-footer {
    display: flex;
    gap: var(--muse-space-60);
    justify-content: center;
    width: 100%;

    @media (width >=600px) {
        justify-content: flex-end;
    }
}

.muse-modal--partial {
    @extend %muse-modal;
}

.muse-modal--full {
    @extend %muse-modal;

    .muse-modal-dialog {
        @media (width >=600px) {
            padding: var(--muse-space-140);
            max-height: 96vh;
            max-width: 1200px;
            min-width: auto;
            min-height: fit-content;
            margin: 0 40px;
        }
    }
}

.muse-modal--mini {
    @extend %muse-modal;

    .muse-modal-dialog {
        @media (width >=600px) {
            padding: var(--muse-space-140);
            max-height: 48vh;
            max-width: 504px;
            min-width: auto;
            min-height: fit-content;
        }

        @media (width >=1024px) {
            max-width: 548px;
        }

        @media (width >=1440px) {
            max-width: 421px;
        }
    }
}

.displayBlock {
    display: block;
}

.displayNone {
    display: none;
}

.info {
    background-color: #e5ecff;
    border-left: 6px solid #1D69CC;
}

.informationPara {
    font-size: 16px;
    line-height: 30px;
    margin-left: 5px;
}

.loginAnchar:focus {
    border: 1px solid black;
}

.breadcrumbs {
    padding: 0 var(--muse-space-160);
    margin-top: 20px;
}

.breadcrumbs>* {
    display: flex;
    margin-right: 10px;
}

.breadcrumbs .crumb:after {
    content: 'Home >';
    margin-left: 10px;
}

.breadcrumbs .crumb:last-child::after {
    display: none;
}

.breadcrumbs .crumb a {
    color: var(--muse-text-accent) !important;
    text-decoration: none !important;
}

.breadcrumbs .crumb a:focus {
    border: 2px solid black;
}

.breadcrumbs .crumb p {
    margin-left: 10px;
}

.crumb #fw-c-content a:not([class]):focus,
#fw-c-content a:not([class]):focus-visible,
#fw-c-content a:not([class]):focus-within {
    box-shadow: none;
}

.muse-accordion-body p {
    display: block;
    color: var(--text-default, #1B1C1D);
}

.muse-accordion-body p a {
    color: var(--muse-text-accent) !important;
    display: inline;
    text-decoration: underline;
}

.muse-accordion-body ol li a {
    color: var(--muse-text-accent) !important;
    display: inline;
    text-decoration: underline;
}

.breadcrumbs .cmp-accordion__button {
    display: block;
    width: 5%;
    text-align: left;
}


/*
loggedout.css
$Revision: 1.2.0.0 $
. used by logged out success and partial success pages only
(c) 1992-2015 Cisco Systems, Inc. All rights reserved.
Terms and Conditions: http://cisco.com/en/US/swassets/sw293/sitewide_important_notices.html
*/
#fw-mb-w1 {
    width: 98%;
}

#fw-mb-w1 a {
    text-decoration: none;
    color: #007FC5;
}

#fw-mb #popup-wrapper {
    padding-top: 10px;
}

#fw-mb #popup-content {
    border: none;
    margin-bottom: 110px;
}

#fw-mb #popup-content-padding {
    margin: 15px 0;
}

#logoutsuccess {
    background-color: #f5f5f5;
    padding: 40px 30px;
}

#logoutsuccess p {
    margin: 0 0 10px 0;
    color: #525252;
    font-size: 14px;
}

#logoutsuccess .title {
    font-size: 26px;
    margin-bottom: 20px;
    color: #444;
}

#logoutsuccess .message {
    font-size: 16px;
    margin-bottom: 30px;
}

#fw-mb #framework-footer {
    border-top: 1px #eaeaea solid;
}

@media only screen and (min-width: 48em) {
    #fw-mb-w1 {
        width: 80%;
    }
}

@media only screen and (min-width: 64em) {
    #fw-mb-w1 {
        width: 60%;
    }
}

.loginAnchar {
    text-decoration: none;
    cursor: pointer;
    color: #1D69CC;
}

.spinner {
    position: relative;
    align-items: center;
    align-content: center;
    width: 50px;
    height: 50px;
}

.spinner::before {
    content: 'Searching...';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 50px;
    height: 50px;
    margin-top: -25px;
    margin-left: -25px;
}

.spinner:not(:required)::before {
    content: '';
    border-radius: 50%;
    border: 1px solid #ccc;
    border-top-color: #03ade0;
    -moz-animation: spinner .6s linear infinite;
    -o-animation: spinner .6s linear infinite;
    animation: spinner .6s linear infinite;
    -webkit-animation: spinner .6s linear infinite;
}

@keyframes spinner {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.spinnerText {
    margin-top: 80px;
    margin-bottom: 80px;
    display: flex;
    justify-content: center;
}

.maxWidth95 {
    max-width: 95%;
}

.subLinkNotificationinnerDiv {
    border: 1px solid;
    background: antiquewhite;
    border-radius: 5px;
    height: auto;
    padding: 20px;
}

#innercontactUsMenu {
    padding: 15px;
}

.highlightLink a:focus-visible {
    box-shadow: 0 0 0 2px var(--muse-border-inverse), 0 0 0 4px var(--muse-border-inverse), 0 0 0 6px var(--muse-border-accent) !important;
    outline: none;
    border-radius: 4px;
}

.cursor-pointer {
    cursor: pointer;
}
.center-align {
    display: flex;
    align-items: center;

    .column-title {
        padding-left: 8px;
        font-size: 16px;
        font-weight: 700;
    }
}

.scrollable-div {
    /* height: 190px;
    overflow: auto; */
    padding: 0 4px;
}

.scrollable-div::-webkit-scrollbar {
    width: 8px;
}

/* Style the scrollbar track */
.scrollable-div::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Style the scrollbar thumb (the draggable part) */
.scrollable-div::-webkit-scrollbar-thumb {
    background-color: #888;
    /* Set thumb color */
    border-radius: 10px;
    /* Rounded corners for the thumb */
}

/* Hover effect for the scrollbar thumb */
.scrollable-div::-webkit-scrollbar-thumb:hover {
    background-color: #555;
    /* Change thumb color on hover */
}

.word-count-indicator {
    text-align: end;
    color: #8E8E8E;
    font-size: 14px;
}

.mr-9 {
    margin-right: 9rem;
}

.p-5 {
    padding: 0 5rem;
}

.manage-profile {
    position: relative;
    cursor: pointer;

    .muse-select {
        display: flex;
        align-items: center;

        .muse-select-wrapper {
            padding: 15px;
            margin-bottom: 0;
            width: 550px;
            position: relative;
    
            strong {
                font-size: 16px;
                font-weight: 700;
            }

            span {
                right: 10px;
                position: absolute;
            }
        }
    }

    .error {
        border-color: var(--muse-border-error);
    }

    .proxy-user-popup {
        position: absolute;
        z-index: 999;
        background: white;
        box-shadow: var(--cds-c-cta-button-focus-primary-dropshadow);
        box-shadow: var(--muse-elevation-default);
        padding: 20px;
        left: 5%;
        width: 58%;

        .input-field {
            background-color: #e5ecff;
            margin-bottom: 10px;

            .muse-input-wrapper {
                padding: 8px;
            }
        }
    }
}

.additional-email-list {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    gap: 0.25rem;
    margin-top: 5px;

    span {
        display: flex;
        align-items: center;
        background-color: #e5ecff;
        padding: 5px 10px;
        font-size: 12px;
        border-radius: 15px;
        margin-right: 4px;

        .icon {
            margin-left: 5px;
            cursor: pointer;
        }
    }
}